import React from "react";
import Helmet from "react-helmet";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import { useAlternateLangs } from "../../components/Hreflangs";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import InfoBox from "../../components/InfoBox";
import QuoteBox from "../../components/QuoteBox";
import CtaPrimary from "../../components/CtaPrimary";
import { currentYear } from "../../components/Helpers";
import ImgScreenshot from "../../components/ImgScreenshot.js";
import ImgContainerFixed from "../../components/ImgContainerFixed";
import H from "../../components/Headline";


const breadCrumbLevels = {
    Koti: "/",
    "Analytiikka Konsultointi": "/fi/analytiikka-konsultointi",
    "GTM Opas": "/fi/google-tag-manager-asennus"
  };
  
// hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/google-tag-manager-setup"
);

  
  const FAQs = `
{
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "Tarvitsenko Google Tag Manageria?",
        "acceptedAnswer": {
        "@type": "Answer",
        "text": "Kyllä, koska verkkosivustosi haluaa todennäköisesti ajaa Google Analyticsia ja muita kolmannen osapuolen skriptitunnisteita. Kaiken tämän asettaminen on paljon helpompaa ja nopeampaa Google Tag Managerin kanssa. Lisäksi sivustosi <a href='https://marketingland.com/audit-of-online-retailer-sites-shows-tag-management-systems-improve-load-times-reduce-errors-62173'>lataa hieman nopeammin</a>."
        }
      },
      {
      "@type": "Question",
      "name": "Milloin minun tulisi käyttää Google Tag Manageria?",
      "acceptedAnswer": {
      "@type": "Answer",
      "text": "Jo jos haluat vain käyttää Google Analyticsia, sinun tulisi käyttää Google Tag Manageria. Tapahtumaseurannan ja muiden konfiguraatioiden <a href='https://bluerivermountains.com/fi/tapahtuman-seuranta'>asettaminen</a> on paljon nopeampaa ja helpompaa GTM:n kanssa. Lisäksi verkossa on monia tutoriaaleja ja oppaita, jotka selittävät esimerkiksi miten <a href='http://bluerivermountains.com/fi/google-analytics-asennus'>Google Analytics asennetaan</a>."
        }
      },
      {
        "@type": "Question",
        "name": "Miten käytän Google Tag Manageria Google Analyticsin kanssa?",
        "acceptedAnswer": {
        "@type": "Answer",
        "text": "Älä laita Google Analytics -tunnistetta sivustosi lähdekoodiin. Lisää vain Google Tag Manager -tunniste ja asenna sitten <a href='https://bluerivermountains.com/fi/google-analytics-asennus'>Google Analytics</a> Google Tag Managerin kautta. Kaikki mukautetut konfiguraatiot, kuten <a href='https://bluerivermountains.com/fi/tapahtuman-seuranta'>tapahtumaseuranta</a> tai muiden skriptitunnisteiden lisääminen, teet ne GTM:ssä."
        }
     },
     {
       "@type": "Question",
       "name": "Mikä on ero Google Analyticsin ja Google Tag Managerin välillä?",
       "acceptedAnswer": {
       "@type": "Answer",
       "text": "Google Analytics on kirjasto, joka kerää tietoja verkkosivustosi käynneistä. Google Tag Manager taas on kirjasto, joka suorittaa sivustollasi muiden kirjastojen tai työkalujen, kuten Google Analyticsin, toteuttamisen. Koska monet näistä työkaluista sisältävät ylimääräisiä JavaScript-koodinpätkiä, jotka lähettävät tietoja niille, asetat ne kaikki GTM:ssä."
       }
    },
    {
      "@type": "Question",
      "name": "Minne sijoitan Google Tag Manager -koodin?",
      "acceptedAnswer": {
      "@type": "Answer",
      "text": "Koodin ensimmäinen osa menee mahdollisimman ylös <head> osioon. Suosittelen sen sijoittamista <head> sisään, mutta sen jälkeen, kun kaikki <style> tai <script> tunnisteet, jotka ovat välttämättömiä sivun renderöimiseksi - koska emme halua viivästyttää niitä. GTM-koodin toinen osa mahdollistaa perustoiminnallisuuden sivustoilla, joissa JavaScript on pois päältä. Se menee mahdollisimman ylös <body> elementtiin. Molempien tunnisteiden sijainnin logiikka on varmistaa GTM:n varhainen lataus. Se mahdollistaa sivun latausprosessin osien häiritsemisen ja hallinnan mahdollisimman aikaisin."
      }
   },
   {
     "@type": "Question",
     "name": "Sisältääkö Google Tag Manager Google Analyticsin?",
     "acceptedAnswer": {
     "@type": "Answer",
     "text": "Ei, mutta Google Tag Managerin avulla voit ottaa Google Analyticsin käyttöön sekunneissa vain muutamalla napsautuksella. Ainoa, mitä tarvitset, on Google Analytics -seurantatunnuksesi. Yleisesti ottaen sinun ei tarvitse käyttää Google Analyticsia Google Tag Managerin kanssa. Ne ovat toisistaan riippumattomia."
     }
  }
  ]
}
`;

const GTMsetup = props => (
    <Layout location={props.location} alternateLangs={alternateLangs}>
  
      <React.Fragment>
        <SEO
          title={`Google Tag Manager -asennusopas: Opas vuodelle ${currentYear}`}
          description="Opi käyttämään GTM:ää Google Analyticsin, tapahtumaseurannan, uudelleenmarkkinointitunnisteiden ja datakerroksen asettamiseen sekä parhaat käytännöt latausten ja ulkoisten linkkien seurannassa."
          lang="fi"
          canonical="/fi/google-tag-manager-asennus"
          image="google-tag-manager-tutorial-hero.png"
          alternateLangs={alternateLangs}
        />
        <MainContent article>
          <ImgScreenshot
            src="gtm-setup/google-tag-manager-tutorial-hero.png"
            alt="Google Tag Managerin käyttöliittymä"
          />
          <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
          <H as="h1">Google Tag Manager -opas</H>

          <p>Google Tag Manager <Link to="/fi/google-tag-manager-konsultti"> -konsulttina </Link> olen asentanut GTM:n yli 100 asiakasverkkosivustolle. Tämä Google Tag Manager -opas opettaa sinulle prosessin, jonka olen hionut vuosien varrella, askel askeleelta, esimerkkien ja videoiden avulla.</p>
        <p>Alempana voit <Link to="/fi/google-tag-manager-asennus">ladata GTM-asetusten konfigurointitiedoston</Link> sisältäen kaikki seuraavat parhaat käytännöt, jotka voit tuoda omaan konttiisi.</p>
        <p>Jos et malta odottaa, siirry suoraan <Link to="/fi/google-tag-manager-asennus">asennus</Link> -oppaaseen tai opi <Link to="/fi/google-tag-manager-asennus">kuinka asettaa Google Tag Manager</Link>. Mutta jos olet <b>aloittelija</b>, on tärkeää ensin ymmärtää <em>miten</em> käyttää <Link to="/fi/tagien-hallinta">tagin hallintajärjestelmää</Link> yhdessä muiden työkalujen kanssa.</p>
        <p>Joten jatka lukemista alapuolelta.</p>
        <H as="h2">Kuinka käyttää Google Tag Manageria?</H>
        <p>Oletan, että tiedät jo <Link to="/fi/mikä-on-google-tag-manager">mikä Google Tag Manager on</Link>. Joten puhutaanpa siitä, kuinka GTM toimii ja miten sitä käytetään.</p>
        <p>Ihannetapauksessa haluat, että sivustosi tai web-sovelluksesi lähdekoodissa on vain <b>yksi</b> kolmannen osapuolen tagi.</p>
        <QuoteBox
          quote={`Ainoa kolmannen osapuolen tagi sivustollasi tulisi olla Google Tag Manager -koodinpätkä.`}
        />
        <p>Kaikki muut tagit toteutetaan sitten itse taginhallintajärjestelmän kautta. Muut markkinointi- ja seurantatagit ovat esimerkiksi Google Analytics (GA), Facebook, Twitter, LinkedIn, AdWords, DoubleClick ja vaikka mitä.</p>
        <p>Pääsyy on <Link to="/fi/google-tag-manager-hyodyt">Google Tag Managerin edut</Link>:</p>
        <ul>
          <li><b>helpompi ja nopeampi</b> markkinointitaggien toteutus</li>
          <li>skaalautuvuus jokaisella sivulla ja useilla verkkotunnuksilla</li>
          <li><b>sisempiä triggereitä</b> lomakkeiden lähetyksille, vierityksen seurannalle ja videoseurannalle</li>
          <li>käyttäjien hallinta useilla GTM-tileillä</li>
          <li>hieman <a rel="noopener" target="_blank" href="https://marketingland.com/audit-of-online-retailer-sites-shows-tag-management-systems-improve-load-times-reduce-errors-62173">nopeampi sivun latausaika</a></li>
        </ul><br />
        <p>Näiden etujen vuoksi jo <a target="_blank" href="https://w3techs.com/technologies/overview/tag_manager">30% kaikista verkkosivustoista käyttää taginhallintajärjestelmää</a>. Ja näistä Google Tag Managerilla on <a target="_blank" rel="noopener" href="https://trends.builtwith.com/analytics/tag-management/traffic/Entire-Internet">94% markkinaosuus</a>.</p>
        <p>Joten, ellei sinulla ole vankkaa syytä olla lisäämättä tagia GTM:ään, yleisenä nyrkkisääntönä, <b>lisää kaikki tagit GTM-konttiin</b>.</p>

        <QuoteBox
          quote={`Käytä GTM:ää yhdistävänä kerroksena sivustosi ja kolmannen osapuolen tagien välillä.`}
        />
        <p>Käytä GTM:ää <b>välikerroksena</b> sivustosi ja kolmannen osapuolen palveluiden välillä. Ilman sitä sivustosi ja kolmannen osapuolen tagit eivät ole suorassa yhteydessä. Nämä palvelut ovat enimmäkseen JavaScript-kirjastoja markkinointi- tai seurantatyökaluja varten, jotka toteutetaan tagilla. Mutta myös muut tagit pätevät.</p>
        <p>Ainoa poikkeus sääntöön koskee, kun teet konversio-optimointia jakotestityökaluilla.</p>
        <p>Koska konversioasteen optimoinnin aikana A/B-testit lataavat sivun eri variantteja. Joten kävijä saattaa nähdä, kuinka sisältöä uudelleenrenderoidaan hetken ajan.</p>
        <p>Välttääksesi CSS-vilkkumisen ja varmistaaksesi, että varianttitestit latautuvat nopeasti, jakotestitagi voidaan myös sijoittaa suoraan lähdekoodiin.</p>
        <p>Nyt kun tämä on selvitetty, katsotaanpa toteutusta.</p>
        <H as="h2">Asenna Google Tag Manager verkkosivustollesi</H>
        <p>Aloitetaan Google Tag Manager -opas näyttämällä, mistä saat Google Tag Manager -koodinpätkän ja minne se asennetaan sivustolle. Voit kirjautua sisään käyttämällä tavallista Google-tiliäsi.</p>
        <ol>
          <li><H as="h3">Luo Google Tag Manager -tili</H>
            Asentaaksesi GTM:n, sinun on ensin mentävä <a rel="noopener" target="_blank"
              href="https://tagmanager.google.com/">viralliselle sivustolle</a> ja luotava uusi Google Tag Manager -tili.<br />
            <ImgScreenshot
              src="google-analytics-setup/google-tag-manager-create-account.png"
              alt="Google Tag Manager -tilin luominen"
              caption="Ensin luo Google Tag Manager -tili ja valitse kontille nimi, kuten verkkosivustosi nimi, ja saat koodinpätkän."
              className="article-img"
            />
          </li>
          <li><H as="h3">Luo web-omaisuus</H>
            Valitse <b>Web</b> omaisuus saadaksesi koodin verkkosivustolle tai web-sovellukselle.<br />
            <ImgScreenshot
              src="google-analytics-setup/google-analytics-setup-via-google-tag-manager-container-creation.png"
              alt="GTM-kontin nimi ja web-omaisuuden valinta"
              className="article-img"
              caption="GTM-tilillä on saatavilla useita konttityyppejä. Verkkosivustoille valitse web. Huomaa, että GTM-tilillä on myös muita konttityyppejä AMP-sivuille, iOS:lle ja Androidille."
            />
          </li>
          <li><H as="h3">Toteuta Google Tag Manager -koodi</H><ul>
            Sen jälkeen sinulle näytetään Google Tag Manager -koodi, joka tulee toteuttaa verkkosivustollasi.<br />
            <ImgScreenshot
              src="google-analytics-setup/google-tag-manager-code-snippet.png"
              alt="Google Tag Manager -koodinpätkä"
              caption="Tämä on Google Tag Manager -kontin tagi. Se koostuu kahdesta osasta. Ohjeet skriptitunnisteiden toteuttamiseen on kirjoitettu jokaisen osan yläpuolelle."
              className="article-img"
            />
            <li>Ota <b>ensimmäinen osa</b> <Link to="/fi/wiki-analytics/säilö-tunniste">konttitunnisteesta</Link> ja aseta se mahdollisimman korkealle <b>head</b> tagiin jokaisella verkkosivustosi sivulla. Tämä varmistaa, että voit laukoa tagit aikaisin sivun latauksen aikana.</li>
            <li><b>Toinen osa</b> on iframe, joka suoritetaan selaimissa, joissa JavaScript on pois päältä. Asenna tunniste mahdollisimman korkealle jokaisen sivun <b>body</b> tagiin.<br />
              <ImgScreenshot
                src="data-layer/positionierung-data-layer-gtm.png"
                alt="dataLayer sijoitettu ennen TMS-tagiä lähdekoodissa"
                caption={`Ensimmäinen tagi <head> osiossa on datakerros. Älä huoli, jos et vielä tiedä mikä se on (ensimmäinen nuoli). Seuraavaksi tulee GTM-tagin ensimmäinen osa (toinen nuoli). Lopuksi, GTM-tagin toinen osa toteutetaan korkealle <body> elementissä. JavaScript-koodi voi olla välillä, mutta datakerros toteutetaan aina ennen GTM-tunnistetta ja <noscript> GTM-tunniste on aina viimeisenä.`}
                className="article-img"
              />
            </li>
          </ul>
          </li>

        </ol>
        <p>Tämä on yleinen tapa toteuttaa GTM.</p>
        <p>Käytätkö suosittua sisällönhallintajärjestelmää? Jos kyllä, voit myös käyttää <b>liitännäistä</b>, joka huolehtii Google Tag Managerin asennuksesta.</p>
        <p>Tämä sanottuna:</p>
        <InfoBox
          type="info"
          headline="Jos CMS:si tarjoaa myös liitännäisen muiden tagien asennukseen"
          h="false"
          dots="true"
          tweetcopy="Jos CMS:si tarjoaa myös liitännäisen muiden tagien asennukseen, älä käytä vielä toista liitännäistä Google Analyticsin, Facebookin tai Google Ads:n asentamiseen. Käytä sen sijaan GTM:ää näiden tagien asentamiseen."
        >
          <p>Älä käytä vielä toista liitännäistä Google Analyticsin, Facebookin tai Google Ads:n asentamiseen.</p>
          <p>Käytä sen sijaan <b>GTM:ää näiden tagien asentamiseen</b>.</p><br />
          <ol>
            <li>Se johtaa nopeampaan sivun latausaikaan</li>
            <li>Se antaa sinulle enemmän vaihtoehtoja tagin konfigurointiin</li>
          </ol>
        </InfoBox>
        <p>GTM:n käyttöliittymä saa myös säännöllisesti päivityksiä uusilla ominaisuuksilla, joten olet melkein aina paremmassa asemassa toteuttaessasi muita markkinointitageja suoraan sen kautta kuin toisen integraation kautta.</p><p>Lisäksi parannukset latausajassa ovat hyväksi <Link to="/fi/wiki-analytics/pomppausprosentti">poistumisprosentille</Link> ja auttavat SEO:ssa.</p>
        <H as="h3">Käytä liitännäistä GTM:n toteuttamiseen</H>
        <p>Alla luettelo yleisimmistä sisällönhallintajärjestelmistä ja niiden liitännäisistä Google Tag Managerin asennukseen.</p>
        <H as="h4">WordPress</H>
        <p>WordPressille on kaksi liitännäistä GTM:n toteuttamiseen, joita käyttäisin. <b>Ensimmäinen</b> on klassinen vaihtoehto nimeltään <a rel="noopener" target="_blank" href="https://wordpress.org/plugins/duracelltomi-google-tag-manager/">Google Tag Manager WordPressille</a>.<br />
          <b>Toinen</b> vaihtoehto on <a rel="noopener" target="_blank" href="https://wordpress.org/plugins/google-site-kit/">Site
            Kit by Google</a>. Sen avulla voit ensisijaisesti lisätä kojelaudan WordPressin hallintapaneeliin, joka näyttää tietoja Google Analytics -tililtäsi ja Google Search Console -datan - mikä on aika siistiä. Se mahdollistaa myös GTM:n asentamisen.</p>
        <H as="h4">Shopify</H>
        <p>Shopifylle on <b>ilmainen</b> liitännäinen GTM:n asennukseen, joka on kekseliäästi nimeltään <em><a rel="noopener" target="_blank"
          href="https://apps.shopify.com/trafficguard?surface_detail=google+tag+manager&amp;surface_inter_position=1&amp;surface_intra_position=6&amp;surface_type=search">Google
          Tag Manager Installer</a></em>.</p>
        <H as="h4">Squarespace</H>
        <p>Squarespacelle ei ole GTM-laajennusta tai -liitännäistä. Mutta voit lisätä GTM-tagin manuaalisesti käymällä <b>sivupalkki</b> &gt; <b>asetukset</b> &gt; <b>lisäasetukset</b> &gt; <b>koodin syöttö</b>.</p>

        <ImgScreenshot
          src="gtm-setup/google-tag-manager-installation-in-squarespace.png"
          alt="Squarespace-navigointivalikko koodin syöttöä varten"
          caption={`Squarespacessa voit toteuttaa GTM:n kohdassa Asetukset > Lisäasetukset > Koodin syöttö`}
          className="article-img"
        />

        <p>Seuraavaksi liität GTM-tagin lomakekenttiin näin:</p>

        <ImgScreenshot
          src="gtm-setup/gtm-code-injection-in-squarespace.png"
          alt="Syötä GTM-koodinpätkät Squarespaceen"
          caption={`Laita GTM-koodin ensimmäinen osa otsikkokenttään. Toinen osa menee alatunnistekenttään.`}
          className="article-img"
        />

        <H as="h4">Wix</H>
        <p>Käy Wix-verkkosivustosi päävalikossa vasemmassa sivupalkissa. Sieltä käy <b>Markkinointi &amp; SEO</b> ja napsauta sitten <b>Markkinointi-integraatiot</b> alempana sivupalkissa.<br />
          Sieltä löydät useita Wixin integraatioita Google Analyticsille, Facebook-pikselille ja myös yhden Wixin laajennuksen Google Tag Managerin toteuttamiseen.</p>

        <ImgScreenshot
          src="gtm-setup/google-tag-manager-setup-in-wix.png"
          alt="Wixin markkinointi-integraatio Google Tag Managerille"
          caption={`Wixissä käytä markkinointi-integraatiota Google Tag Managerille.`}
          className="article-img"
        />
        <p>Napsauta yhdistä ja asenna Google Tag Manager.</p>

        <H as="h2">Kuinka tarkistaa, toimiiko GTM?</H>
        <InfoBox
          type="info"
          headline="Kun kirjaudut ensimmäistä kertaa GTM:ään"
          h="false"
          dots="true"
          tweetcopy="Kun kirjaudut ensimmäistä kertaa GTM:ään...Siirry lähetyspainikkeeseen ja julkaise tyhjä kontti. Muuten, kun testaat, toimiiko GTM, skripti palauttaa 400 virheilmoituksen ja vietät tunteja selvittäessäsi miksi. 😭"
        >
          <p>Siirry lähetyspainikkeeseen ja julkaise <b>tyhjä kontti</b>.</p>
          <p>Muuten, kun testaat, toimiiko GTM, skripti palauttaa <b>400 virheilmoituksen</b> ja vietät tunteja selvittäessäsi miksi. 😭 <br /><br />Se on klassikko 😉</p>
        </InfoBox>
        <p>Kun olet toteuttanut GTM-skriptin ja <b>julkaissut kontin</b> version (tärkeää), voit testata, toimiiko Google Tag Manager tekemällä jonkin näistä tarkistuksista:</p>
        <ol>
          <li><H as="h3">Esikatselu- ja debug-tila</H>Kirjaudu GTM-tilillesi ja napsauta <b>esikatselua</b>. Avaa sitten uusi välilehti selaimessa ja käy verkkosivustollasi. GTM-vianetsintäikkuna pitäisi avautua ikkunan alareunaan, jos GTM toimii oikein.<br />
            <ImgScreenshot
              src="event-tracking/google-tag-manager-event-trigger-preview.png"
              alt="Aktivoi GTM-vianetsintätila"
              caption={`Aktivoi GTM-vianetsintätila tarkistaaksesi, toimiiko GTM oikein.`}
              className="article-img"
            />

          </li>
          <li><H as="h3">Chrome Developer Tools</H><b>Avaa Chrome Developer Tools</b> napsauttamalla hiiren oikealla painikkeella mitä tahansa sivusi kohtaa ja valitsemalla <em>tarkastele</em> (Vaihtoehtoisesti F12 Windowsilla ja Shift+CTRL+J Macilla).<br />
            Siirry sitten <b>verkko</b>-välilehteen ja <b>lataa verkkosivu uudelleen samanaikaisesti</b> (F5 Windowsilla ja CMD+Shift+R
            Macilla). Verkkovälilehti täyttyy kaikista verkkopyynnöistä, jotka ovat tarpeen sivun lataamiseksi.<br />
            Kirjoita ylävasemmalla olevaan suodatuskenttään <em>gtm.js</em> löytääksesi JavaScript-koodipyynnön ja varmista, että sillä on
            <b>200 statuskoodi</b>.<br /><br />
            Näytän sinulle:<br /><br />
            <video

              loading="lazy"
              title={`Tarkista, toimiiko Google Tag Manager`}
              autoplay
              muted
              playsinline
              loop
              controls
              css="max-width:100%;border:3px solid lightgrey;"
            >
              <source src="/video/check-if-gtm-is-working.mp4" type="video/mp4" />
            </video>
            <br />
            <b>Jos sinulla ei ole 200 statuskoodia, ehkä unohdit lähettää ja julkaista kontin ensin GTM:ssä?</b></li>
          <li><H as="h3">Google Tag Assistant</H>Asenna <a rel="noopener" target="_blank" href="https://chrome.google.com/webstore/detail/tag-assistant-by-google/kejbdjndbnbjgmefkgdddjlbokphdefk">Google Tag Assistant</a> Chrome-laajennus ja käynnistä se sivustollasi. Sen pitäisi tunnistaa GTM-kontti ID.<br />

            <ImgContainerFixed width="452px">
              <ImgScreenshot
                src="gtm-setup/gtm-validation-with-tag-inspector.png"
                alt="Google Tag -tarkastaja varmistaa, että GTM latautuu oikein"
                caption={`Voit myös käyttää Chrome-laajennusta Google Tag Assistant varmistaaksesi, että Google Tag Manager toimii oikein.`}
                className="article-img"
              />
            </ImgContainerFixed>
          </li>
        </ol>
        <H as="h2">Kuinka asentaa Google Tag Manager?</H>
        <p>Google Tag Managerin asennuksessa voit tehdä monia edistyneitä konfiguraatioita. Joten <b><em>miten</em></b> asennat GTM:n, riippuu siitä, mitä muita työkaluja aiot käyttää <Link to="/fi/tagien-hallinta">taginhallintajärjestelmässäsi</Link>.</p>
        <p>Siksi olen koonnut yhteen kaikki asiaankuuluvat oppaat, jotka kattavat kaiken, mitä haluat mahdollisesti asettaa GTM-tilillesi - esimerkkien kera. Seuraa yksinkertaisesti tätä Google Tag Manager -opasta ja luo näin vahva taginhallinnan perusta sivustollesi.</p>
        <p>Vain oppaassa, joka käsittelee datakerroksen toteuttamista, vaaditaan koodaustaitoja tai mahdollisesti web-kehittäjiä.</p>
        <p><b>Huom</b>: Tässä Google Tag Manager -oppaassa käytämme GTM:ää <b>manuaalisesti</b> asettamalla uusia tageja ja triggereitä jokaiselle tapahtumalle. Lähestymistapa ei ole super skaalautuva, mutta se on riittävän nopea ja helppo, samalla kun se täyttää useimmat seuranta-ambitiot ja on edelleen sovellettavissa muihin edistyneisiin asennuksiin.</p>
        <p>Suuremmat verkkosivustot ja verkkokaupat vaativat <b>skaalautuvan taginhallintaratkaisun</b>. Siksi <Link to="/fi/data-kerros">datakerros</Link> toteutetaan keskeisenä osana tapahtumien seuraamiseksi. Tällaisella asetuksella voit käyttää tapahtumakäsittelijöitä sen sijaan, että asetat tageja, triggereitä ja muuttujia jokaiselle tapahtumalle.</p>
        <ol>
          <li>
            <H as="h3">Aseta Google Analytics -seuranta</H>
            <p>Tämä on ensimmäinen askel kaikille. Opi tässä oppaassa, kuinka toteuttaa vankka Google Analytics -seuranta, tavoitteet, suppilot ja omat vierailut pois lukien liikenteestä. Lisäksi parhaat käytännöt.</p>

            <Link to="/fi/google-analytics-asennus"><CtaPrimary color="purple" arrow="true">Aseta Google Analytics</CtaPrimary></Link>

          </li>
          <li>
            <H as="h3">Aseta tapahtumaseuranta</H>
            <p>Kerran perustavanlaatuisen seurannan toteutus toimii kuten pitäisi, haluat myös oppia seuraamaan käyttäjien
              sitoutumista. Kuinka usein esimerkiksi vierailija lähettää lomakkeita ja napsauttaa lähetyspainiketta tai muuta HTML-elementtiä? Minun <Link to="/fi/tapahtuman-seuranta">tapahtumaseurannan</Link> oppaani selittää tämän tarkasti <b>painikkeen napsautukselle</b> ja voit käyttää samaa menetelmää mihin tahansa muuhun klikkauksen seurantaan.</p>
            <Link to="/fi/tapahtuman-seuranta"><CtaPrimary color="purple" arrow="true">Aseta tapahtumaseuranta</CtaPrimary></Link>
          </li>
          <li>
            <H as="h3">Lisää uudelleenmarkkinointitunnisteet</H>
            <p>Yleisin käyttötapaus GTM:lle <em>GA:n asennuksen jälkeen</em> on uudelleenmarkkinointitunnisteiden lisääminen verkkosivustolle. Loppujen lopuksi ne muodostavat suurimman osan kolmannen osapuolen markkinointitunnisteista ja seurantakoodeista, jotka täyttävät sivustojemme koodipohjan.<br />
              Siksi toteutamme ne GTM-tilimme kautta pitääksemme koodipohjan puhtaana markkinointi- ja analytiikkatunnisteista samalla kun hyödynnämme <Link to="/fi/google-tag-manager-hyodyt">Google Tag Managerin etuja</Link>.</p>
            <p>Opi lisäämään yleisimmät uudelleenmarkkinointitunnisteet digimarkkinoinnin alalla, <b>Facebook-pikseli</b> ja <b>Google Ads -uudelleenmarkkinointitunniste</b>.</p>
            <H as="h4">Lisää Facebook-pikseli</H>
            <ul>
              <li>
                Ensin tarvitset <b>Facebook-pikseli ID</b>:si. Käy Facebookin <a rel="noopener" target="_blank"
                  href="https://www.facebook.com/events_manager2/list/pixel/">Tapahtumien hallinta</a> -sivulla ja napsauta <b>vihreää plus-symbolia</b> luodaksesi Facebook-pikselin. Tämän jälkeen pikseli ID:si näkyy näytöllä.
                <ImgScreenshot
                  src="gtm-setup/get-fb-pixel.png"
                  alt="Tapahtumien hallinta Facebookissa näyttää pikseli ID:n"
                  caption={`Löydä Facebook-pikseli ID:si Tapahtumien hallinnasta.`}
                  className="article-img"
                />
              </li>
              <li>
                Luo sitten Google Tag Managerin kautta <b>uusi tagi</b>, kutsu sitä esimerkiksi <em>Facebook - Sivun
                  katselu</em> ja käy galleriaa läpi <b>tagimallien</b> osalta.
              </li>
              <li>
                Etsi <em>Facebook</em> ja valitse Facebook Pixel.<br />

                <ImgScreenshot
                  src="gtm-setup/fb-pixel-template-gallery-gtm.png"
                  alt="GTM:n tagimallit näyttävät Facebook-pikselin"
                  caption={`Toteuta Facebook-pikseli GTM:n tagimallien kautta.`}
                  className="article-img"
                />
              </li>
              <li>
                Lisää <b>Facebook-pikseli ID</b>:si ja napsauta <b>tallenna</b>.
              </li>
              <li>
                Aseta tagi laukaisemaan <b>kaikilla sivuilla</b>.
                <br /><br />
                <video

                  loading="lazy"
                  autoplay
                  muted
                  playsinline
                  loop
                  controls
                  title={`Asenna Facebook-pikseli Google Tag Managerissa`}
                  css="max-width:100%;border:3px solid lightgrey;"
                >
                  <source src="/video/add-facebook-pixel-with-gtm.mp4" type="video/mp4" />
                </video>
              </li>
              <li>
                Tämän jälkeen napsauta <b>lähetä</b> oikeassa yläkulmassa viedäksesi tagin liveksi.
              </li>
            </ul>
            <H as="h4">Lisää Google Ads -uudelleenmarkkinointi</H>
            <ul>
              <li><b>Ensin</b>, hanki <b>Google Ads -konversio ID</b> yleisöstäsi kohdasta <b>Jaettu kirjasto &gt; Yleisöt</b>. Käyttöliittymä on muuttunut hiljattain, mutta etsi <b>tagitiedot</b> tai <b>asennustagi</b> löytääksesi alla olevan tiedon.
                <br />
                <ImgContainerFixed width="432px">
                  <ImgScreenshot
                    src="gtm-setup/google-ads-conversion-id.png"
                    alt="Koodinpätkät Google-konversio ID:lle ja konversio-etiketille"
                    caption={`Ota konversio ID ja konversio-etiketti yleisösi tagitiedoista.`}
                    className="article-img"
                  />
                </ImgContainerFixed>
              </li>
              <li>Sitten GTM:ssä, siirry <b>tagit</b>-osioon ja napsauta <b>uusi</b> lisätäksesi uuden markkinointitagimme.</li>
              <li>Anna sille nimi, kuten <em>Google Ads - Sivun katselu</em>.</li>
              <li>Valitse muuttujan tyypiksi <b>Google Ads Remarketing</b>.</li>
              <li>Aseta <b>konversio ID</b> ja tarvittaessa <b>konversiotunniste</b>. Aseta sitten tagi laukaisemaan <b>kaikilla sivuilla</b> ja napsauta <b>tallenna</b>.<br /><br />Näytän sen tässä videossa:
                <video

                  loading="lazy"
                  autoplay
                  muted
                  playsinline
                  loop
                  controls
                  title={`Asenna Google Ads sivun katselu GTM:llä`}
                  css="max-width:100%;border:3px solid lightgrey;"
                >
                  <source src="/video/google-ads-page-view.mp4" type="video/mp4" />
                </video>
              </li>
              <li>Napsauta <b>lähetä</b> oikeassa yläkulmassa viedäksesi markkinointitagin liveksi.</li>
            </ul>
            {/* Linkki -&gt; kuinka asettaa konversio Google Tag Managerissa<br /> */}
            {/* [//]: # (### Toteuta lomakeseuranta)<br /> */}
            {/* [//]: # (Linkki -&gt; lomakeseuranta) */}
            {/* toteuta vierityksen seuranta */}
            {/* toteuta videoseuranta */}
          </li>
          <li>
            <H as="h3">Toteuta datakerros</H>
            <p>Haluat toteuttaa datakerroksen, jos asetat tageja säännöllisesti ja se vie liikaa aikaa ja on yksinkertaisesti liian työlästä.</p>
            <p>Toinen etu on, että voit käyttää tietokannasta saatavia tietoja laukaisijoiden laukaisemiseen tai lähettää ne tapahtumatietoina. Myös muut ulkoiset tietolähteet voidaan integroida. Verkkosivustot, jotka tarvitsevat verkkokaupan seurantaa, kuuluvat yleensä tähän ryhmään.</p>
            <p>Artikkelini datakerroksesta selittää toteutuksen, datakerrosmuuttujat ja kuinka konfiguroida mukautettu seuranta skaalautuvalla tavalla, mikä on tyypillinen käyttötapaus <b>suurille verkkokaupoille</b>, jotka tarvitsevat parannettua verkkokaupan seurantaa.
            </p>

            <Link to="/fi/data-kerros"><CtaPrimary color="purple" arrow="true">Toteuta datakerros</CtaPrimary></Link>
          </li>
        </ol>
        <H as="h2">Parhaat käytännöt jokaiseen GTM-asennukseen</H>
        <p>Jokainen kerta, kun asennan Google Tag Managerin, asennus sisältää muutamia konfiguraatioita, jotka lisään aina. Nämä
          parhaat käytännöt ovat sovellettavissa ja hyödyllisiä useimmille yrityksille ja niiden ei pitäisi puuttua mistään GTM-oppaasta. Katso alla oleva lista ja valitse ne, jotka ovat hyödyllisiä sinulle.</p>
        <p>Alempana voit <Link to="/fi/google-tag-manager-asennus">ladata GTM-asetusten konfiguraation</Link>, joka sisältää kaikki nämä parhaat käytännöt, ja tuoda sen omaan konttiisi.</p>
        <H as="h3">Seuraa ulkoisia linkkejä</H>
        <p>Ulkoisten linkkien klikkausten seuraaminen tarkoittaa kaikkien klikkausten seuraamista ulkoisiin linkkeihin, jotka johtavat verkkosivustoltasi muille sivustoille.
          Ulkoisten linkkien seuranta on paras käytäntö, joka kertoo sinulle, mille verkkosivustoille lähetät vierailuja ja auttaa sinua arvioimaan kävijöidesi kiinnostusta.<br />Ulkoisten linkkien seurannan toteuttamiseksi on <b>kolme vaihetta</b>:</p>
        <ol>
          <li>
            <H as="h4">Luo mukautettu tapahtumamuuttuja GTM:ssä</H>
            <ul>
              <li>Käy <b>käyttäjän määrittämät muuttujat</b> GTM-yhteenvedosta ja napsauta <b>uusi &gt; automaattinen tapahtumamuuttuja</b>.</li>
              <li>Sen on tarkoitus arvioida klikattavan elementin linkin URL-osoite ja palauttaa <code>true</code>, jos <code>href</code>-attribuutissa on ulkoinen linkki - tai <code>false</code>, jos linkki on sisäinen.</li>
              <li>Anna uudelle muuttujalle nimi, kuten <em>Linkki on ulkoinen</em> ja valitse <em>muuttujan tyyppi</em> olemaan <b>Elementti URL</b> ja <em>Komponenttityyppi</em> <b>on ulkoinen</b>. Napsauta sitten <b>tallenna</b>.<br />

                <ImgScreenshot
                  src="gtm-setup/auto-event-variable-for-outbound-links.png"
                  alt="Ulkoisten linkkien automaattisen tapahtumamuuttujan asetukset"
                  caption="Automaattinen tapahtumamuuttuja Google Tag Managerissa saa arvon TRUE, kun klikattavan linkin osoite on ulkoinen ja osoittaa ulkoiselle sivulle."
                  className="article-img"
                />

              </li>
            </ul>
          </li>
          <li>
            <H as="h4">Luo uusi laukaisin ulkoisille linkeille</H>
            <ul>
              <li>Sivupalkin navigoinnissa laukaisimien alla napsauta uusi ja luo uusi laukaisin GTM:ssä.</li>
              <li>Valitse <em>laukaisimen tyyppi</em> olemaan <b>vain linkit</b> ja nimeä se esimerkiksi <em>Tapahtuma - Ulkoinen
                linkki</em>.</li>
              <li>Aseta laukaisin <em>laukeamaan joissakin linkkiklikkauksissa</em> ja valitse uusi automaattinen tapahtumamuuttuja <b>Linkki on ulkoinen</b> vastaamaan <code>true</code>:<br />


                <ImgScreenshot
                  src="gtm-setup/outbound-links-trigger-config.png"
                  alt="Ulkoinen linkkiseuranta -laukaisimen konfigurointi"
                  caption={`Laukaisimen asetukset ulkoisten linkkien seurantaan.`}
                  className="article-img"
                />

              </li>

            </ul>
          </li>
          <li>
            <H as="h4">Luo tagi GA-tapahtumalle</H>
            <ul>
              <li>Käy <b>muuttujat</b>-osiossa ja napsauta <b>määritä</b> kohdassa <em>sulautetut muuttujat</em>. Aktivoi sitten <b>klikkaus URL</b> -muuttuja. Sulje uudelleen ja siirry tagit-osioon.</li>
              <li>Luo uusi <b>Google Analytics: Universal Analytics</b> -tagi nimeltä <em>Tapahtuma - Ulkoinen linkki</em> ja aseta <em>seurantatyyppi</em> olemaan <b>tapahtuma</b>.</li>
              <li><b>Kategoriaksi</b> lisää <code>Ulkoinen linkkiklikkaus</code>, <b>toiminnoksi</b> <code>klikkaus</code> ja <b>tunnisteeksi</b> viittaa juuri aktivoituun GTM-muuttujaamme. Tätä varten käytämme kaksoiskaarisulkuja <code>{`{{ Click URL }}`}</code>.</li>
              <li>Tapahtuman <b>arvo</b> voidaan asettaa <code>0</code>, ellei ulkoiselle linkille voida antaa nimellisarvoa (esim. affiliate-linkit).</li>
              <li><b>Non-interaction hit</b> -asetus jää <code>false</code>-tilaan, koska ulkoinen klikkaus on todellakin käyttäjän toiminta.</li>
              <li><b>Google Analytics -asetukset</b> -muuttuja tulisi olla sama käyttäjän määrittämä muuttuja kuin sivun katselutagissasi.<br />


                <ImgScreenshot
                  src="gtm-setup/google-analytics-event-tag-external-link-clicks.png"
                  alt="Google Analytics -tapahtumatagin asetukset ulkoisten linkkien seuraamiseksi"
                  caption={`Tapahtumatagin asetukset ulkoisten linkkien seuraamiseksi.`}
                  className="article-img"
                />

              </li>

              <li>Viimeisenä vaiheena, vieritä alaspäin <em>laukaisua</em> osioon ja <b>valitse juuri luotu laukaisin</b> <em>Tapahtuma - Ulkoinen Linkki</em>. Napsauta sitten <b>tallenna</b> ja <b>lähetä</b> konttisi muutokset yleisnäkymästä.</li>
            </ul>
          </li>
        </ol>
        <H as="h3">Seuraa klikkauksia sähköpostiosoitteisiin</H>
        <p>Sähköpostien klikkaukset ovat hyödyllinen mittari, joka yleensä korreloi puheluiden tai fyysisessä kaupassa käyntien kanssa.<br />Asenna Google Analytics -seuranta sähköpostiklikkauksille seuraamalla alla olevan oppaan vaiheita:</p>
        <ol>
          <li>
            <H as="h4">Lisää uusi laukaisin sähköpostiosoitteiden klikkauksille</H>
            <ul>
              <li>Aktivoi <b>klikkaus URL</b> sulautettu muuttuja. Voit ohittaa tämän vaiheen, jos olet jo tehnyt sen
                edellisessä asennuksessa. Muussa tapauksessa: Siirry <b>muuttujat</b> -osioon ja <b>määritä</b> <em>sulautetut
                  muuttujat</em> lisätäksesi <b>klikkaus URL</b> muuttujan. Sulje paneeli ja siirry
                laukaisimet-osioon.</li>
              <li>Valitse <b>laukaisimet</b>, napsauta <em>uusi</em> ja luo uusi laukaisin nimeltä esim. <em>Tapahtuma - Sähköpostiklikkaus</em>, aseta tyypiksi <b>klikkaus - vain linkit</b> ja anna sen laukaista vain <em>joissain linkkiklikkauksissa</em>.</li>
              <li>Ehto näille linkkiklikkauksille, aseta avattavat valikot olemaan <b>Click URL</b>, sitten <b>sisältää</b> ja sitten lisää merkkijono <code>mailto:</code><br /></li>

              <ImgScreenshot
                src="gtm-setup/email-click-trigger-settings-in-gtm.png"
                alt="Laukaisimen konfigurointi sähköpostiosoitteiden klikkausten seuraamiseksi"
                caption={`Laukaisimen konfigurointi sähköpostiosoitteiden klikkausten seuraamiseksi.`}
                className="article-img"
              />

            </ul>
          </li>
          <li>
            <H as="h4">Aseta GA-tapahtumatagi</H>
            <ul>
              <li>Luo uusi tagi, valitse tagityypiksi <b>Google Analytics: Universal Analytics</b> ja anna sille nimeksi <em>Tapahtuma - Sähköpostilinkki</em>. Valitse sitten <b>tapahtuma</b> <em>seurantatyypiksi</em>.</li>
              <li><b>Kategoria</b> voi olla staattinen merkkijono <code>Sähköpostilinkin klikkaus</code>, <b>toiminto</b> on <code>klikkaus</code> ja <b>tunniste</b> viittaa <code>{`{{ Click URL }}`}</code>.</li>
              <li>Tapahtuman <b>arvo</b> voi jälleen olla <code>0</code>, ellei sille voida antaa merkityksellistä arvoa. Jos esimerkiksi tietäisit, että joka kymmenes sähköpostikontakti johtaa 100 dollarin myyntiin, voisit antaa keskimääräiseksi tapahtuma-arvoksi 10 dollaria.</li>
              <li><b>Non-interaction hit</b> tulisi olla <code>false</code>, koska vuorovaikutus on vapaaehtoinen ja siten aktiivinen.</li>
              <li><b>Google Analytics -asetukset</b> muuttuja tulisi olla sama kuin sivun katselutagillesi tai ulkoiselle
                linkkitagille.<br />


                <ImgScreenshot
                  src="gtm-setup/google-analytics-event-tag-emails.png"
                  alt="GA-sähköpostiklikkaustapahtuman konfigurointi"
                  caption={`GA-tapahtumatagin konfigurointiasetukset sähköposteille.`}
                  className="article-img"
                />

              </li>
              <li>Viimeinen vaihe on siirtyä alaspäin kohtaan <em>laukaisu</em> ja <b>valita juuri luotu laukaisin</b> <em>Tapahtuma - Sähköpostiklikkaus</em>. Tämän jälkeen, <b>tallenna</b> ja siirry yleisnäkymään <b>lähettääksesi</b> muutoksesi.</li>
            </ul>
          </li>
        </ol>
        <H as="h3">Seuraa klikkauksia puhelinnumeroihin</H>
        <p>Puhelinnumeroiden klikkausten seuranta on ensisijaisesti hyödyllistä mobiililaitteilla. Puhelinnumerolinkin napautus käynnistää suoraan puhelun. Työpöytälaitteilla hiiren klikkaukset eivät yleensä käynnistä mitään. Kuten sähköpostiklikkausten seurannassa, se on hyvä mittari yhteydenottoprosenttien vahvistamiseen, koska se korreloi muiden tapojen kanssa ottaa yhteyttä yritykseen.</p>
        <p>Opi konfiguroimaan GTM puhelinnumeroiden klikkausten seuraamiseksi noudattamalla alla olevia ohjeita.</p>
        <ol>
          <li>
            <H as="h4">Luo laukaisin puhelinnumeroiden klikkauksille</H>
            <ul>
              <li>Kuten edellisissä oppaissa, tarvitsemme <b>klikkaus URL</b> -muuttujan aktivoituna <em>sulautetuista muuttujista</em>.</li>
              <li>Luo sitten uusi laukaisin <em>Tapahtuma - Puheluklikkaus</em> tyypiltään <b>klikkaus - vain linkit</b>, joka laukeaa vain <em>joissain linkkiklikkauksissa</em>.</li>
              <li>Täytä laukaisimen ehto seuraavasti: <b>Click URL</b> - <b>sisältää</b> ja sitten merkkijono <code>tel:</code>. Napsauta sitten tallenna.<br />

                <ImgScreenshot
                  src="gtm-setup/phone-number-trigger-in-gtm.png"
                  alt="GTM-asetukset puhelinnumero-laukaisijalle"
                  caption={`Asetukset laukaisijalle, joka laukeaa puhelinnumeroiden klikkauksista.`}
                  className="article-img"
                />


              </li>
            </ul>
          </li>
          <li>
            <H as="h4">Luo puhelinnumero-tapahtumatagi</H>
            <ul>
              <li>Lisää jälleen <b>Universal Analytics -tagi</b> tyypiltään <b>tapahtuma</b> ja nimeä se <em>Tapahtuma - Puheluklikkaukset</em></li>
              <li><b>Kategoria</b> on staattinen merkkijono <code>Puhelinnumeron klikkaus</code>, <b>toiminto</b> on <code>klikkaus</code> ja <b>tunnisteena</b> käytämme jälleen sulautettua muuttujaa kaksoiskaarisuluilla, <code>{`{{ Click URL }}`}</code>.</li>
              <li>Jos et voi liittää keskimääräistä arvoa puheluun johtavalle klikkaukselle, pidä se arvossa <code>0</code>. Muussa tapauksessa lisää keskimääräinen arvo.</li>
              <li>Tapahtuman <b>non-interaction hit</b> tulisi asettaa <code>false</code>, koska tämä on aktiivinen, vapaaehtoinen vuorovaikutus. Valitse <b>asetukset muuttuja</b> käyttäjän määrittämistä muuttujista, joita käytät myös sivun katseluille.



                <ImgScreenshot
                  src="gtm-setup/ga-event-phone-number.png"
                  alt="GA-puhelinnumeroklikkaustapahtuman konfigurointi"
                  caption={`GA-puhelinnumeroklikkaustapahtuman konfigurointi.`}
                  className="article-img"
                />
              </li>
              <li>Nyt yhdistä juuri luotu laukaisin tähän GA-tapahtumatagiin siirtymällä laukaisualueelle ja valitsemalla uusi laukaisin <em>Tapahtuma - Puheluklikkaus</em>. Viimeinen vaihe on <b>tallentaa</b> ja napsauttaa lähetä.</li>
            </ul>
          </li>
        </ol>
        <H as="h3">Seuraa latauksia</H>
        <p>Kävijöiden materiaalien latausten seuraaminen on hyvä merkki sitoutumisesta. Tällaisia latauksia voivat olla esimerkiksi
          e-kirjat, Excel-taulukot, kuvat, videot tai musiikki.</p><p>Latausten seuranta toimii hyvin erottamaan kävijäryhmät, jotka eivät olleet kiinnostuneita sivun sisällöstä ja kävijät, jotka todella olivat kiinnostuneita ja latasivat tarjoamasi materiaalin.</p>
        <p>Seuraa tätä opasta oppiaksesi latausten seurannan asettamisen:</p>
        <ol>
          <li>
            <H as="h4">Konfiguroi GTM-laukaisin latausklikkauksille</H>
            <ul>
              <li>Luo uusi laukaisin nimeltä <em>Tapahtuma - Lataukset</em>, valitse <em>laukaisutyypiksi</em> <b>klikkaus - vain linkit</b>, joka laukeaa vain <b>joissain linkkiklikkauksissa</b>.</li>
              <li>Ehdoksi aseta sulautettu muuttuja <b>Click URL</b> <b>päättyy</b> merkkijonoon <code>.pdf</code> esimerkiksi PDF-latausten seuraamiseksi. Muuta tiedostopääte merkkijonoa vastaamaan mitä tahansa latausformaattia, jota tarjoat.
              </li>
              <li>Jos haluat seurata <b>useita tiedostopäätteitä</b> latauksissa, voimme käyttää säännöllistä lauseketta. Muuta ehto olemaan <b>Click URL</b> ja sitten <b>vastaa RegEx (sivuuttaa kirjainkoko)</b> ja lisää tämä RegEx-merkkijono <code>.pdf|.mp4|.mp3|.xlsx|.xls|.epub|.jpeg$</code>. Voit vapaasti lisätä muita sivustollesi merkityksellisiä tiedostopäätteitä ja poistaa niitä, jotka eivät ole merkityksellisiä. Viimeistele napsauttamalla <b>tallenna</b>.<br />


                <ImgScreenshot
                  src="gtm-setup/gtm-trigger-download-events.png"
                  alt="Lataustapahtumien laukaisimen konfigurointi sulautettujen muuttujien kanssa"
                  caption={`Lataustapahtumien laukaisimen konfigurointi sulautetun muuttujan kanssa.`}
                  className="article-img"
                />


              </li>
            </ul>
          </li>
          <li>
            <H as="h4">Seuraa latauksia Google Analytics -tapahtumalla</H>
            <ul>
              <li>Lisää uusi tagi tyypiltään Universal Analytics, valitse <b>tapahtuma</b> seurantatyypiksi ja nimeä se esimerkiksi <em>Tapahtuma - Lataukset</em>.
              </li>
              <li>Tapahtuman <b>kategoria</b> on merkkijono <code>Lataus</code>, <b>toiminto</b> on merkkijono <code>klikkaus</code> ja <b>tunniste</b> on <code>{`{{ Click URL }}`}</code>.</li>
              <li>Tapahtuman <b>non-interaction hit</b> on <code>false</code>, koska kyseessä on aktiivinen sitoutuminen.</li>
              <li>Käytä käyttäjän määrittämistä muuttujista samaa <b>asetukset-muuttujaa</b> kuin muissa seurantatageissa.<br />

                <ImgScreenshot
                  src="gtm-setup/google-analytics-event-tag-downloads.png"
                  alt="Tapahtumatagi latausten seurantaan"
                  caption={`Tapahtumatagin asetukset latausten seurantaan.`}
                  className="article-img"
                />

              </li>

              <li>Lisää nyt laukaisin tähän tagiin vierittämällä alaspäin ja valitse <em>Tapahtuma - Lataukset</em>.</li>
              <li>Seuraava vaihe, napsauta <b>tallenna</b>, siirry yleisnäkymään ja <b>lähetä</b> muutokset.</li>
            </ul>
          </li>
        </ol>
        {/* todo */}
        {/* <H as="h3">Lisää vierityksen seuranta</H> */}
        <H as="h2">Opas tagien ja laukaisimien testaamiseen</H>
        <p>Google Tag Manager -opas ei olisi täydellinen ilman osuutta virheenkorjauksesta. Testataksesi mitä tahansa aiemmista tapahtumakonfiguraatioista ja varmistaaksesi, että ne toimivat, tee jokin seuraavista:</p>
        <ol>
          <li><H as="h3">GTM-esikatselutila</H>Ensimmäiseksi, käynnistetään esikatselu- ja virheenkorjaustila. Google Tag Managerin yleiskatsauksessa napsauta oikeassa yläkulmassa olevaa <b>esikatselu</b> -painiketta. Avaa sitten uusi
            välilehti <b>samassa selaimessa</b>, ja huomaat, että GTM-virheenkorjausikkuna avautuu ikkunan alareunaan.<br /><br />
            Esikatselutilassa suorita itse tagit ja laukaisimet. Napsauta esimerkiksi ulkoista linkkiä tai puhelinnumeroa ja katso, näkyykö
            laukaisin ja tagisi GTM-virheenkorjausikkunassa yhdessä lisäämiesi tapahtumatietojen kanssa.<br />

            <ImgScreenshot
              src="event-tracking/google-tag-manager-debugging-mode.png"
              alt="google tag manager virheenkorjaus tapahtumille"
              className="article-img"
              caption={`Google Tag Managerin virheenkorjaus avautuu automaattisesti, kun esikatselutila on aktivoitu, ja näyttää tietoja tageista, laukaisimista ja muuttujista.`}
            />


          </li>
          <li><H as="h3">Google Analyticsin reaaliaikainen raportti tapahtumille</H>Toinen menetelmä on kirjautua Google Analyticsiin ja sitten käydä <b>reaaliaikaisessa raportissa tapahtumille</b>. Tapahtumat pitäisi näkyä muutaman sekunnin kuluttua laukaisimen laukeamisesta.

            <ImgScreenshot
              src="event-tracking/google-analytics-event-tracking-realtime-report.png"
              alt="google analytics reaaliaikainen raportti tapahtumille"
              className="article-img"
              caption={`Voit tarkistaa GTM-konfiguraatiot Google Analyticsin reaaliaikaisella raportilla.`}
            />


          </li>
          <li><H as="h3">Chrome-laajennus virheenkorjaukseen</H>Asenna Chrome-laajennus tagien ja laukaisimien virheenkorjaukseen. Suosittelen <a rel="noopener" target="_blank"
            href="https://chrome.google.com/webstore/detail/trackie/iphjjodolgbelaogcefgpegebgecopeh">Trackieta</a> tai <a
              rel="noopener" target="_blank"
              href="https://chrome.google.com/webstore/detail/omnibug/bknpehncffejahipecakbfkomebjmokl">Omnibugia</a>, mutta
            muitakin ratkaisuja on saatavilla. Kun olet lisännyt laajennuksen Chromeen, voit avata <b>Chrome Developer
              Tools</b> ja saatavilla on uusi välilehti. Se näyttää kaikki tiedot laukaistuista taginhallintasäännöistä. Jos sitten laukaistat laukaisimet virheenkorjaustilassa, laajennus näyttää laukaisimen ja tapahtuman
            tiedot.
            <br />
            <ImgScreenshot
              src="gtm-setup/test-gtm-trigger-and-tag-with-trackie.png"
              alt="Trackie Chrome -laajennus näyttää tapahtumatiedot."
              className="article-img"
              caption={`Trackie Chrome -laajennus näyttää tapahtumatiedot Chrome Developer Toolsissa.`}
            />

            <ImgScreenshot
              src="gtm-setup/test-gtm-trigger-and-tag-with-omnibug.png"
              alt="Omnibug Chrome -laajennus näyttää tapahtumatiedot virheenkorjaukseen"
              className="article-img"
              caption={`Omnibug Chrome -laajennus näyttää tapahtumatiedot virheenkorjaukseen Chrome Developer Toolsissa.`}
            />
          </li>
        </ol>

        <H as="h2">Lataa GTM-konfiguraatiotiedosto</H>
        <p>Koska yllä olevat konfiguraatiot ovat yleisesti hyödyllisiä useimmille Google Tag Manager -toteutuksille, loin yllä olevan
          GTM-asetuksen tiedostoksi, jonka voi tuoda muihin Google Tag Manager -kontteihin.<br />
          Se on <code>.json</code>-tiedosto asetuksilla ja nimeämiskäytännöillä, joita kävimme läpi. Voit vain tuoda konttikoodin ilman, että sinun tarvitsee asettaa mitään manuaalisesti.</p>
        <p>Voit <b>käyttää sitä uudessa kontissa</b> säästääksesi aikaa tagien asettamisessa itse, tai voit <b>tuoda
          ne olemassa olevaan konttiisi</b> ja päivittää Google Analytics -asetukset muuttujan mukaanlukien seurantatunnuksesi omaan tunnukseesi.</p>
        <p>Voit ladata ja asentaa nämä konfiguraatiot (jokaisella on tagit, laukaisimet ja muuttujat) asettaaksesi Google Tag Managerin:</p>
        <ul>
          <li>Seuraa ulkoisia linkkejä</li>
          <li>Seuraa sähköpostiklikkauksia</li>
          <li>Seuraa puhelinnumeroklikkauksia</li>
          <li>Seuraa latauksia</li>
        </ul>
        <p className="baseline">Tuomalla vain konttiasetukset ja käyttämällä niitä. Havainnollistamistarkoituksessa lisäsin Google Analytics -asetukset muuttujan Google Analytics -seurantatunnuksella <em>UA-12345678-9</em>.</p> <p><b>Päivitä GA-seurantakoodi omaksesi</b> tai
          vaihtoehtoisesti, <b>vaihda GA-asetukset muuttuja tagikonfiguraatiossa omaksesi</b>.</p>
        <p>Lataa GTM-asetukset ja katso alla, miten tuoda ne.</p>
        <a href="/downloads/best-practice-gtm-configs.json" download="best-practice-gtm-configs.json" ><CtaPrimary color="purple">Lataa GTM-asetukset</CtaPrimary></a>
        <H as="h3">Tuo asetukset GTM-konttiisi</H>
        <p>Saadaksesi kaiken irti tästä GTM-oppaasta, noudata alla olevia vaiheita ja tuo asetukset GTM-konttiisi:</p>
        <ul>
          <li>siirry kohtaan <b>ylläpito</b> &gt; <b>tuo kontti</b>.</li>
          <li>valitse juuri lataamasi JSON-tiedosto.</li>
          <li>valitse <b>uusi työtila</b> nimeltä esim. <em>Import Bluerivermountains</em>.</li>
          <li>valitse <b>tuontivaihtoehdoksi</b> <b>yhdistä</b> ja <b>nimeä uudelleen</b> kaikki ristiriitaiset tagit, laukaisimet ja muuttujat.</li>
          <li>napsauta <b>vahvista</b> ja paina <b>lähetä</b> ja <b>julkaise</b>-painiketta julkaistaksesi tagit.<br /><br />Näytän sen tässä videossa:

            <video

              loading="lazy"
              title={`Opas videon avulla GTM-kontin tuomiseksi`}
              autoplay
              loop
              controls
              css="max-width:100%;border:3px solid lightgrey;"
            >
              <source src="/video/import-gtm-container.mp4" type="video/mp4" />
            </video>

          </li>

          <li>Lopuksi, vaihda GA-seurantatunnus Google Analytics -asetukset muuttujassa omaan seurantatunnukseesi tai päivitä asetukset muuttuja
            tageissa omiin asetuksiisi.</li>
        </ul>
        <H as="h2">Huomautus henkilötiedoista</H>
        <p>Meidän täytyy olla tietoisia seurattavasta tiedosta. Data ei ole vain dataa, koska mailla on tietosuojalainsäädäntöjä, jotka vaikuttavat siihen, millaista tietoa voimme kerätä seurannan aikana.</p>

        <p>Samoin Googlella on ehtoja, jotka kieltävät henkilötietojen seuraamisen ja lähettämisen heidän palvelimilleen.</p>

        <p>Tämän huomioon ottaen:</p>
        <p>Yleisesti ottaen, <b>sähköpostit tai puhelinnumerot ovat henkilötietoja (PII)</b>, eikä meidän ole sallittua
          lähettää niitä Google Analytics -tilillemme, koska se on <a rel="noopener" target="_blank" href="https://support.google.com/analytics/answer/2795983">heidän palveluehtojensa vastaista</a>.</p>
        <p>Kuitenkin, verkkosivustollamme olevat puhelinnumerot tai omat yrityksen sähköpostiosoitteet tuskin ovat yksityisiä, koska ne eivät ole käyttäjien tietoja vaan omiamme ja julkisesti saatavilla verkkosivustolla.<br />
          Siitä huolimatta, jos Google Analytics <em>koskaan</em> tarkistaisi tietokantansa ja löytäisi kyseisiä tietoja, he eivät voisi tietää,
          että ne eivät ole oikeasti PII-tietoja.<br />
          Siksi suosittelen, että et ota riskejä ja <b>hämärrä kaikki sähköpostiosoitteet ja puhelinnumerot, jotka lähetetään
            Google Analytics -tilille</b>.</p>
        <p>Simo Ahava on tehnyt hienoa työtä ja kirjoittanut <a rel="noopener" target="_blank"
          href="https://www.simoahava.com/gtm-tips/remove-pii-google-analytics-hits/">mukautetun tehtäväskriptin henkilötietojen poistamiseksi
          Google Analytics -osumista</a>, ja suosittelen, että toteutat sen yhdessä yllä olevien konfiguraatioiden kanssa.<br />
          Se ei ole pakollista, mutta toteuttamalla sen vältät mahdolliset sekaannukset siitä, onko sinulla henkilötietoja vai ei.</p>
        <H as="h2">UKK</H>
        <H as="h3">Tarvitsenko Google Tag Manageria?</H>
        <p>Kyllä, koska verkkosivustosi haluaa todennäköisesti käyttää Google Analyticsia ja muita kolmannen osapuolen skriptitageja. Kaiken
          tämän asettaminen on paljon helpompaa ja nopeampaa Google Tag Managerilla. Lisäksi sivustosi <a rel="noopener" target="_blank"
            href="https://marketingland.com/audit-of-online-retailer-sites-shows-tag-management-systems-improve-load-times-reduce-errors-62173">lataa
            hieman nopeammin</a> myös.</p>
        <H as="h3">Milloin minun tulisi käyttää Google Tag Manageria?</H>
        <p>Jo jos haluat vain käyttää Google Analyticsia, sinun tulisi käyttää Google Tag Manageria. Tapahtumaseurannan, verkkotunnusten välisen seurannan tai lomakeseurannan asettaminen ovat yleisiä seuraavia vaiheita, mutta paljon <b>nopeampaa</b> ja <b>helpompaa</b> GTM:n kanssa kuin ilman sitä. Siellä on myös sisäänrakennettuja triggereitä vierityksen seurantaan ja videon seurantaan, sekä monia oppaita verkossa, jotka selittävät kuinka <Link to="/fi/google-analytics-asennus">asettaa Google Analytics</Link> sen kanssa.</p>
        <H as="h3">Kuinka käytän Google Tag Manageria Google Analyticsin kanssa?</H>
        <p>Kirjaudu <a href="https://analytics.google.com" rel="noopener" target="_blank">analytics.google.com</a> Google-tililläsi ja hanki Google Analytics -seurantakoodisi mukaan lukien seurantatunnus. Älä nyt lisää Google Analytics -tagia sivustosi lähdekoodiin. Ainoa kovakoodattu tagi tulisi olla Google Tag Manager -tagi. Siirry siis <a target="_blank" rel="noopener" href="https://tagmanager.google.com/">tagmanager.google.com</a> saadaksesi GTM-koodinpätkä ja toteuta se sen sijaan jokaiselle sivullesi. Lopuksi, toteuta GA-koodi sisäänrakennetun tagin kautta, lisää seurantatunnuksesi ja jatka <Link to="/fi/google-analytics-asennus">asettamaan Google Analytics</Link> <b>Google Tag Managerin kautta</b>.
          Markkinointitagien lisäämiseen tai mukautettujen tagien konfigurointiin käytät aina GTM:ää.</p>
        <H as="h3">Mikä on ero Google Analyticsin ja Google Tag Managerin välillä?</H>
        <p>Google Analytics on kirjasto vierailujen ja sitoutumisen datan keräämiseen verkkosivustollasi. Google Tag Manager taas on kirjasto, joka pyörii sivustollasi toteuttaakseen muita kirjastoja tai <em>seurantatyökaluja</em>, kuten Google Analytics. Koska monilla markkinointi- ja analytiikkatyökaluilla on ylimääräisiä JavaScript-pätkiä, käytät GTM-käyttöliittymää asettaaksesi ne kaikki.</p>
        <H as="h3">Mihin laitan Google Tag Manager -koodin?</H>
        <p>Koodin <b>ensimmäinen</b> osa menee <em>mahdollisimman korkealle</em> <code>&lt;head&gt;</code>-osioon. Suosittelen sen toteuttamista <code>&lt;head&gt;</code>-osioon, mutta kaikkien <code>&lt;style&gt;</code> tai <code>&lt;script&gt;</code>-tagien jälkeen, jotka ovat välttämättömiä sivun renderöimiseksi - koska emme halua viivyttää niitä.<br />
          Koodin <b>toinen</b> osa mahdollistaa perustoiminnallisuuden selaimissa, joissa JavaScript on kytketty pois päältä. Se menee <em>mahdollisimman korkealle</em> <code>&lt;body&gt;</code>-elementtiin.<br />
          Logiikka molempien tagien sijainnin takana on varmistaa GTM:n aikainen lataus. Se mahdollistaa mukautettujen tagien laukaisemisen mahdollisimman aikaisin sivun latauksen aikana.</p>

        <br />
        <ImgScreenshot
          src="google-analytics-setup/google-tag-manager-code-snippet.png"
          alt="Google Tag Manager -koodi"
          className="article-img"
          caption={`GTM-tagissa on kaksi osaa. Ensimmäinen sijoitetaan korkealle head-tagiin ja toinen heti body-tagin avauksen jälkeen (katso alla).`}
        />

        <br />

        <ImgScreenshot
          src="data-layer/positionierung-data-layer-gtm.png"
          alt="datalayerin sijoittaminen ennen taginhallintatagi lähdekoodissa"
          className="article-img"
          caption={`Yleinen järjestys pitäisi aina olla 1. datakerrosobjekti 2. Google Tag Manager, molemmat head-tagissa ja 3. toinen GTM-koodi korkealla body-tagissa`}
        />

        <H as="h3">Sisältääkö Google Tag Manager Google Analyticsin?</H>
        <p>Ei, mutta Google Tag Managerin avulla voit toteuttaa Google Analyticsin sekunneissa vain muutamalla napsautuksella. Ainoa
          asia, jota tarvitset, on <Link to="/fi/google-analytics-asennus">Google Analytics -seurantatunnuksesi</Link>. Yleisesti ottaen sinun ei kuitenkaan tarvitse käyttää Google Analyticsia Google Tag Managerin kanssa.
          Ne ovat <b>itsenäisiä</b> toisistaan.</p>
        <H as="h3">Kuinka pääsen Google Tag Manageriin?</H>
        <p>Käy osoitteessa <a target="_blank" href="https://tagmanager.google.com/" rel="noopener">tagmanager.google.com</a> ja kirjaudu sisään Google-tililläsi päästäksesi Google Tag Manageriin. Aloittaaksesi GTM:n käytön, luo uusi tili ja valitse kohdealustaksi web-ominaisuus. Ota sitten koodinpätkä ja asenna se jokaiselle verkkosivustosi sivulle.</p>
        <Helmet>
          <script type="application/ld+json">{FAQs}</script>
        </Helmet>
      </MainContent>
    </React.Fragment>

  </Layout >
);

export default GTMsetup;